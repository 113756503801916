$ = require("jquery");

//スマホメニュー
window.onload = setTimeout(function () {
	document.getElementById("nav-toggle").onclick = function () {
		document.querySelector("body").classList.toggle("open");
	};
},2000);
//ドロワーメニュークリックで閉じる
$('#sp-nav a[href]').on('click', function(event) {
	$('.nav-ham').trigger('click');
});

//FV slick
jQuery(function($){
  $('.fv_slick').slick({
    centerMode: true,
    centerPadding: '20%',
    slidesToShow: 1,
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerMode: true,
          centerPadding: '13%',
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          slidesToShow: 1
        }
      }
    ]
  });
});

//スムーススクロール
$(document).on('click', 'a[href*="#"]', function() {
		let time = 500;
		let target = $(this.hash);
		if (!target.length) return;
		let targetY = target.offset().top - 100;
		$('html,body').animate({scrollTop: targetY}, time, 'swing');
		return false;
	});
	$(function () {
    // 別ページの場合は以下
  var urlHash = location.hash;
  if (urlHash) {
    $('body,html').stop().scrollTop(0);
    setTimeout(function(){
      // ヘッダー固定の場合はヘッダーの高さを数値で入れる、固定でない場合は0
      var headerHight = 100;
      var target = $(urlHash);
      var position = target.offset().top - headerHight;
      $('body,html').stop().animate({scrollTop:position}, 400);
  }, 100);
}
});

//サイドエリアsticky
window.onload = setTimeout(function () {
$(document).ready(function() {
  return $("[data-sticky_column]").stick_in_parent({
    parent: "[data-sticky_parent]"
  });
});
},1000);

jQuery(function($){
  //faqアコーディオン
  $(".qaac dd").css("display", "none");
    $(function(){
		$(".qaac dt").on("click", function() {
			$(this).next().slideToggle();
			if($(this).hasClass("dt_clicked")){ // クリックされた要素がclickedクラスだったら
				$(this).removeClass("dt_clicked");
			}else{
				$(this).addClass("dt_clicked");
			}
		});
	});
});


